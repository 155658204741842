import React from 'react';
import dynamic from 'next/dynamic';
import Skeleton from '@/src/components/loading/list';
import { ubtError } from '@/src/sources/common/lightUbt';

export default dynamic(() => import(/* webpackChunkName: "SSGListContainer" */ './ListContainer'), {
	ssr: false,
	loading: ({ error }) => {
		if (error) {
			ubtError(error);
		}
		return <Skeleton />;
	},
});
