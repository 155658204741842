/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { NextPage, GetServerSideProps } from 'next';
import Head from 'next/head';
import BasePage from '@/src/components/common/BasePage';

import { lightSendPv, ubtDevTrace } from '../../sources/common/lightUbt';
import Skeleton from '../../components/loading/list';
import List from '@/src/containers/list/ListContainer/async';

// import '../../../public/style/detect.css';
import '../../../public/style/searchbox.css';
import '../../../public/style/listloading.css';
import '../../../public/style/list.css';

import '../../styles/list/todo/variable.scss';
import '../../styles/list/todo/book-tooltip.scss';
import '../../styles/list/todo/smallstyle.scss';
import '../../styles/list/todo/servicePackage.scss';
import '../../styles/list/todo/flightOnlineListIcon/flightOnlineListIcon.scss';
import '../../styles/list/todo/footer.breadcrumb.index.scss';
import '../../styles/list/todo/notice.scss';
import '../../styles/list/todo/list.scss';
import '../../styles/list/todo/list-override.scss';

type ListPageOut = Record<string, any>;

enum FlightWayType {
	S = 'S',
	D = 'D',
	M = 'M',
}

export function getPageId(scope: string, flightWay: FlightWayType) {
	if (scope === 'd') {
		if (flightWay == FlightWayType.S) {
			return '10320673302';
		} else if (flightWay == FlightWayType.D) {
			return '10320673304';
		} else {
			return '10320673306';
		}
	} else {
		if (flightWay == FlightWayType.S) {
			return '10320672927';
		} else if (flightWay == FlightWayType.D) {
			return '10320672928';
		} else {
			return '10320672929';
		}
	}
}

const index: NextPage<ListPageOut> = ({ renderData, setCurrentPageId }) => {
	const { GlobalSearchCriteria, seo, seoUrl, captchaUrl, offlineInfo } = renderData || {};
	const [firstRender, setFirstRender] = useState(true);

	const { scope, flightWay } = GlobalSearchCriteria;
	const pageId = getPageId(scope, flightWay);

	useEffect(() => {
		// window.GlobalSearchCriteria = GlobalSearchCriteria;
		var c = 'PRE_SEARCH_COST_TIME',
			d = sessionStorage.getItem(c);
		if (d)
			try {
				var e = JSON.parse(d);
				e && e.s0 && ((e.s1 = +new Date()), sessionStorage.setItem(c, JSON.stringify(e)));
			} catch (f) {
				console.error(f);
			}
		setFirstRender(false);
	}, []);

	useEffect(() => {
		if (!firstRender) {
			setCurrentPageId(pageId);
			lightSendPv(pageId);
		}
	}, [firstRender, pageId]);

	useEffect(() => {
		ubtDevTrace('hotel_captcha_url', { url: captchaUrl });
		const entries = performance && performance.getEntries();
		if (entries && entries.length) {
			const captchaResource = entries.find((i) => i.initiatorType === 'link' && i.name.includes('/sdt'));
			if (captchaResource) {
				ubtDevTrace('hotel_captcha_load', { captchaResource });
			}
		}
	}, []);

	return (
		<>
			<Head>
				<meta name="description" content={seo.description} />
				<meta name="keywords" content={seo.keywords} />
				<meta httpEquiv="Cache-Control" content="public" />
				<title>{seo.title}</title>
				{!!seoUrl && (
					<>
						<meta name="applicable-device" content="pc" />
						<meta
							name="mobile-agent"
							content={`format=html5;url=https://m.ctrip.com/html5/flight/${seoUrl}.html`}
						/>
						<link
							rel="alternate"
							media="only screen and (max-width: 640px)"
							href={`https://m.ctrip.com/html5/flight/${seoUrl}.html`}
						/>
						<link rel="canonical" href={`https://flights.ctrip.com/booking/${seoUrl}.html`} />
						<meta httpEquiv="Cache-Control" content="no-transform " />
					</>
				)}
			</Head>
			<input type="hidden" id="page_id" value={pageId} />
			<input type="hidden" id="page_start_time" value={+new Date()} />
			{firstRender ? <Skeleton /> : React.createElement(List, { offlineInfo })}
		</>
	);
};

index.displayName = 'page(list)';

export const getServerSideProps: GetServerSideProps<ListPageOut> = async (context) => {
	const { query, req } = context;
	const { renderData, aresModules, aresManifest, aresAssetPrefix } = req.headers;
	return { props: { ...query, renderData, aresModules, aresManifest, aresAssetPrefix } };
};

export default BasePage({})(index);
